import { useCodeMessage } from '@/components/trade-ui/trade-view/swap/components/order-listener';
import { LANG } from '@/core/i18n';
import { useWs1080 } from '@/core/network/src/hooks/use-ws-1080';
import { message, playAudio } from '@/core/utils';
import { Swap } from '../..';
import { ISwapAssetWallet } from '../../../account/assets/types';
import { assetsInstance as Assets } from '../assets';
import { orderInstance as Order } from '../order';
import { PendingItemType, PositionItemType } from '../order/field';

export const useSwapWsUpdateOrders = () => {
  const codeMessage = useCodeMessage();
  useWs1080(
    (data) => {
      swapWsUpdateOrders(data, codeMessage);
    },
    [codeMessage]
  );
};
const swapWsUpdateOrders = (data: any, codeMessage: any) => {
  const quoteId = Swap.Trade.store.getSnapshot('quoteId');
  let isUsdtType = Swap.Info.getIsUsdtType(quoteId);
  const { tradeNoticeSound } = Swap.Info.getTradePreference(isUsdtType, { withHooks: false });
  const pendingU = [...Order.getPending(true)];
  const pending = [...Order.getPending(false)];
  const positionU = [...Order.getPosition(true)];
  const position = [...Order.getPosition(false)];

  // try {
  if (data.e === 'ORDER_TRADE_UPDATE') {
    (data.tso ?? []).forEach((v: any) => {
      // "{\"uid\":814507938663514112,\"E\":1714129329338,\"e\":\"ORDER_TRADE_UPDATE\",
      // \"brand\":\"BYD\",\"o\":[{\"S\":\"2\",\"c\":\"8605549203419848715\",\"s\":\"btc-usdt\"
      // ,\"sw\":\"W001\",\"X\":\"NEW\",\"x\":\"ORDER\",\"i\":8605549203419848715,\"cp\":true,
      // \"sp\":\"32050.05\",\"wt\":\"TRAILING_STOP_MARKET\",\"AP\":\"64100.1\"}]}"

      const isUsdt = /-usdt/i.test(v.s);
      (isUsdt ? pendingU : pending).forEach((item, index, arr) => {
        if (`${v.c}` === `${item.orderId}`) {
          arr[index] = { ...item, triggerPrice: v.sp, callbackValue: v.cv };
        }
      });
      (isUsdt ? positionU : position).forEach((item, index, arr) => {
        if (`${v.sw}` === `${item.subWallet}` && `${v.s}`.toUpperCase() === `${item.symbol}`.toUpperCase() && `${v.S}` !== `${item.side}`) {
          arr[index] = { ...item, cbVal: Number(`${v.AP}`.sub(`${v.sp}`)) };
        }
      });
    });
    (data.o ?? []).forEach((v: any) => {
      const object: PendingItemType = {
        orderId: v.i,
        symbol: v.s,
        volume: v.q,
        positionSide: v.ps,
        reduceOnly: v.R,
        closePosition: v.Rp,
        price: v.p,
        avgPrice: v.ap,
        dealVolume: v.z,
        subWallet: v.sw,
        leverageLevel: v.le,
        activationPrice: v.AP,
        callbackRate: v.cr,
        callbackValue: v.cv,
        marginType: v.mt == 'cross' ? 1 : 2,
        basePrecision: v.bp,
        baseShowPrecision: v.bsp,
        orderType: v.op,
        side: v.S !== 'SELL' ? '1' : '2',
        status: ({ NEW: '1', CANCELED: '4', PARTIALLY_FILLED: '3', FILLED: '2', EXPIRED: '6', EXPIRED_IN_MATCH: '6' } as any)[v.X] || '0',
        type:
          (
            {
              MARKET: '2',
              LIMIT: '1',
              LIQUIDATION: '3',
            } as any
          )[v.o] || 1,
        //
        action: '',
        ctime: Number(v.T),
        direction: '',
        mtime: 0,
        otocoOrder: null,
        priceType: 0,
        strategyType: null,
        triggerOrders: [],
        triggerPrice: '',
      };
      // console.log('1080 object', object);
      const isUsdt = /-usdt/i.test(object.symbol);

      switch (v.X) {
        case 'NEW': // 追加
          (isUsdt ? pendingU : pending).push(object);
          break;
        case 'PARTIALLY_FILLED': // 更新
          (() => {
            let mydata = isUsdt ? pendingU : pending;
            let index = mydata.findIndex((v) => v.orderId === object.orderId);
            if (index !== -1) {
              mydata[index] = { ...mydata[index], ...object };
            }
          })();
          break;
        case 'FILLED': // 删除
        case 'CANCELED':
        case 'EXPIRED':
          (() => {
            let mydata = isUsdt ? pendingU : pending;
            let index = mydata.findIndex((v) => v.orderId === object.orderId);
            if (index !== -1) {
              mydata.splice(index, 1);
            }
          })();
          break;

        default:
          break;
      }
    });
  } else if (data.e === 'ACCOUNT_UPDATE') {
    let usdtWallets: ISwapAssetWallet[] = [...Assets.getAssetStore(true).getSnapshot('wallets')];
    let coinWallets: ISwapAssetWallet[] = [...Assets.getAssetStore(false).getSnapshot('wallets')];

    (data?.a ?? []).forEach((subData: any) => {
      (subData?.B ?? []).forEach((v: any) => {
        const usdt = v.a === 'USDT';
        const wallet = v.sw;
        const currency = v.a;
        const wallets = usdt ? usdtWallets : coinWallets;

        const walletIndex = wallets.findIndex((v) => v.wallet === wallet);
        if (walletIndex != -1) {
          const assetsData = wallets[walletIndex].accounts[currency];
          wallets[walletIndex].accounts[currency] = {
            ...assetsData,
            accb: v.wb,
            frozen: v.fm,
            positionMargin: v.pm,
            bonusAmount: v.ba,
            voucherAmount: v.va,
          };
        }
      });
      Assets.getAssetStore(true).wallets = usdtWallets;
      Assets.getAssetStore(false).wallets = coinWallets;
      (subData?.P ?? []).forEach((v: any) => {
        const isUsdt = /-usdt/i.test(v.s);
        const object: PositionItemType = {
          subWallet: v.sw,
          marginType: v.mt == 'cross' ? 1 : 2,
          symbol: v.s,
          availPosition: v.pa,
          currentPosition: v.pa,
          margin: v.iw,
          side: v.S !== 'SELL' ? '1' : '2',
          mmr: v.mmr,
          basePrecision: v.bp,
          baseShowPrecision: v.bsp,
          leverage: v.le,
          r: v.r,
          avgCostPrice: v.ep,
          positionSide: v.ps,
          accb: Assets.getBalanceData({ usdt: isUsdt, withHooks: false, walletId: v.sw, code: v.s }).accb,
          maxAddMargin: v.mam,
          maxSubMargin: v.msm,
          autoAddMargin: 0,
          callbackValue: null,
          closeOrderId: null,
          closeOrderPrice: null,
          closeOrderVolume: null,
          frozen: null,
          isClose: null,
          liquidationPrice: v.lp,
          markPrice: '',
          cbVal: 0,
          mm: 0,
          orders: [],
          positionId: '',
        };
        let mydata = isUsdt ? positionU : position;
        let index = mydata.findIndex((v) => Order.positionIsSame(v, object));
        if (index == -1) {
          if (Number(v.pa) != 0) {
            mydata.push(object);
          }
        } else {
          if (Number(v.pa) != 0) {
            mydata[index] = { ...mydata[index], ...object };
          } else {
            mydata.splice(index, 1);
          }
        }
      });
    });
  }
  if (data.pw) {
    if (`${data.pw.c}` === '200') {
      message.success(LANG('下单成功'), 1);
    } else if (data.pw.c) {
      let info = (codeMessage as any)[data.pw.c];
      if (info) {
        message.error(info, 1);
      }
    }
    if (`${data.pw.audio}` === '1' && tradeNoticeSound) {
      playAudio('/static/music/swap_order_sound.mp3');
    }
  }

  Order.setPending(true, pendingU);
  Order.setPending(false, pending);
  Order.setPosition(true, positionU);
  Order.setPosition(false, position);
  // } catch (e) {}
};
