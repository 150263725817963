import { useTheme } from '@/core/hooks';
import { clsx } from '@/core/utils';
import { MediaInfo } from '@/core/utils/src/media-info';
import { Dropdown } from 'antd';
import { useState } from 'react';
import css from 'styled-jsx/css';
import { DropdownMenuItem } from './select-menu';
import { ACCOUNT_TYPE } from './types';
import { useFormatCryptoOptions } from './use-crypto-options';
import { useOptions } from './use-options';

export const AccountTypeSelect2 = ({
  children,
  selectedValue,
  onChange,
  sourceAccount,
  targetAccount,
  positiveTransfer,
  wallets,
  crypto,
  width,
}: {
  children: React.ReactNode;
  onChange: (args: { value: ACCOUNT_TYPE; positiveTransfer: boolean; wallet?: string }) => void;
  selectedValue: string;
  sourceAccount: ACCOUNT_TYPE;
  targetAccount: ACCOUNT_TYPE;
  positiveTransfer: boolean;
  wallets: string[];
  crypto: string;
  width?: number;
}) => {
  const [open, setOpen] = useState(false);
  const { isDark } = useTheme();
  const options = useOptions({ positiveTransfer, positiveAccount: sourceAccount });
  const { getCryptoOptions } = useFormatCryptoOptions({ sourceAccount, targetAccount });

  const imgs = {
    [ACCOUNT_TYPE.SWAP_U]: 'common-assets-u',
    [ACCOUNT_TYPE.SWAP]: 'common-assets-m',
    [ACCOUNT_TYPE.SPOT]: 'common-assets-spot',
    [ACCOUNT_TYPE.P2P]: 'common-assets-spot',
    [ACCOUNT_TYPE.LITE]: isDark
      ? '/static/images/account/transfer/assets_c.png'
      : '/static/images/account/transfer/assets_c_light.png',
  };
  const overlay = (
    <div className='account-wallet-type-dropdown-menu'>
      {options.map((opt, i) => (
        <DropdownMenuItem
          key={i}
          opt={opt}
          getCryptoOptions={getCryptoOptions}
          i={i}
          selectedValue={selectedValue}
          crypto={crypto}
          sourceAccount={sourceAccount}
          targetAccount={targetAccount}
          positiveTransfer={positiveTransfer}
          setOpen={setOpen}
          onChange={onChange}
          wallets={wallets}
          img={imgs[opt.value]}
        />
      ))}
    </div>
  );
  const stopPropagation = (e: any) => {
    if (e.cancelable) {
      e.preventDefault();
    }
    e.stopPropagation();
  };
  return (
    <Dropdown
      open={open}
      autoAdjustOverflow
      onOpenChange={(open) => setOpen(open)}
      dropdownRender={(menu) => overlay}
      trigger={['click']}
      placement='bottom'
      overlayClassName={clsx('account-wallet-type-select-menus', !isDark && 'transfer-modal-light', 'base-drop-view')}
    >
      <div onClick={stopPropagation}>
        {children}
        <style jsx>{styles}</style>
      </div>
    </Dropdown>
  );
};

const styles = css`
  :global(.account-wallet-type-select-menus) {
    :global(.account-wallet-type-dropdown-menu) {
      box-shadow: var(--theme-trade-select-shadow);
      border-radius: 8px;
      padding: 0 10px;
      background: var(--theme-background-color-4);
      width: 360px;
      max-height: 280px;
      overflow-y: auto;
      position: relative;
      left: 11px;
      top: -2px;
      border-radius: 5px;
      color: var(--theme-font-color-1);
      :global(.selected-item) {
        :global(.title) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          padding: 10px 0;
          :global(.left) {
            display: flex;
            align-items: center;
            :global(> div) {
              margin-left: 14px;
            }
          }
          :global(.expand) {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: var(--theme-font-color-3);

            :global(> .arrow) {
              margin-left: 4px;
            }
          }
          :global(.expand.active) {
            :global(> .arrow) {
              transform: rotate(180deg);
            }
          }
        }
        :global(> .item-childs) {
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 5px;

          :global(> .item-child) {
            width: 110px;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            padding: 10px 10px 8px 10px;
            font-size: 12px;
            background: var(--theme-border-color-5);
            margin-bottom: 5px;
            margin-right: 5px;
            border-radius: 6px;
            border: 1px solid transparent;
            &:nth-child(3n) {
              margin-right: 0;
            }
            :global(.name) {
              margin-top: 3px;
            }
            :global(.price) {
              text-align: center;
              color: var(--theme-font-color-3);
            }
          }
          :global(> .item-child.active) {
            border: 1px solid var(--skin-primary-color);
            background: rgba(var(--skin-primary-color-rgb), 0.1);
          }
        }
      }
    }
    @media ${MediaInfo.mobile} {
      :global(.account-wallet-type-dropdown-menu) {
        margin: 0 15px;
        left: unset;
        :global(.selected-item) {
          :global(> .item-childs) {
            :global(> .item-child) {
              width: calc(50% - 2.5px);
              &:nth-child(3n) {
                margin-right: 5px;
              }
              &:nth-child(2n) {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
  :global(.type-select-menus.transfer-modal-light) {
    :global(.account-wallet-type-dropdown-menu) {
      background: #fff;
    }
  }
`;
